/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-large.less 2013-1-16 *******/
@media (min-width: 1400px) {
  .desk {
    margin-right: 45px;
    margin-left: 45px;
  }
}
.partner {
  margin-top: 80px;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 1.53061224%;
  margin-left: 1.53061224%;
  width: 96.93877551%;
}
.area h2,
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part,
.area > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim {
  width: 30.27210884%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 0%;
  margin-left: 0%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 100%;
}
.area > .slim .tiny {
  width: 100%;
}
@media (min-width: 1400px) {
  .area {
    width: 100%;
  }
  .area > .unit {
    margin-right: 1.48148148%;
    margin-left: 1.48148148%;
    width: 97.03703704%;
  }
  .area h2,
  .area .foot {
    margin-right: 0%;
    margin-left: 0%;
  }
  .area .part,
  .area > .grid table {
    margin-right: 0%;
    margin-left: 0%;
    width: 100%;
  }
  .area > .slim {
    width: 30.37037037%;
  }
  .area > .slim h2,
  .area > .slim .foot,
  .area > .slim .part,
  .area > .slim.grid table {
    margin-right: 0%;
    margin-left: 0%;
  }
  .area > .slim .part,
  .area > .slim.grid table {
    width: 100%;
  }
  .area > .slim .tiny {
    width: 100%;
  }
}
.wide.pure,
.wide.seam {
  width: 57.88265306%;
}
@media (min-width: 1400px) {
  .wide.pure,
  .wide.seam {
    width: 57.98518519%;
  }
}
.slim.pure,
.slim.seam {
  width: 35.68877551%;
}
@media (min-width: 1400px) {
  .slim.pure,
  .slim.seam {
    width: 35.91111111%;
  }
}
.flat.wide h2,
.flat.wide .part.part,
.flat.wide .foot {
  margin: 1.53061224%;
}
@media (min-width: 1400px) {
  .flat.wide h2,
  .flat.wide .part.part,
  .flat.wide .foot {
    margin: 1.48148148%;
  }
}
.flat.wide .tall {
  width: 58.03571429%;
}
@media (min-width: 1400px) {
  .flat.wide .tall {
    width: 58.07407407%;
  }
}
.flat.wide .tiny {
  width: 35.84183673%;
}
@media (min-width: 1400px) {
  .flat.wide .tiny {
    width: 36%;
  }
}
.flat.wide .show.tall:first-child:nth-last-child(4),
.flat.wide .show:first-child:nth-last-child(4) ~ .show.tall,
.flat.wide .show.tall:first-child:nth-last-child(3),
.flat.wide .show:first-child:nth-last-child(3) ~ .show.tall {
  padding-bottom: 54.62611607%;
}
@media (min-width: 1400px) {
  .flat.wide .show.tall:first-child:nth-last-child(4),
  .flat.wide .show:first-child:nth-last-child(4) ~ .show.tall,
  .flat.wide .show.tall:first-child:nth-last-child(3),
  .flat.wide .show:first-child:nth-last-child(3) ~ .show.tall {
    padding-bottom: 54.66222222%;
  }
}
.flat.wide .show.tiny:first-child:nth-last-child(4),
.flat.wide .show:first-child:nth-last-child(4) ~ .show.tiny {
  padding-bottom: 16.16788903%;
}
@media (min-width: 1400px) {
  .flat.wide .show.tiny:first-child:nth-last-child(4),
  .flat.wide .show:first-child:nth-last-child(4) ~ .show.tiny {
    padding-bottom: 16.2454321%;
  }
}
.flat.wide .show.tiny:first-child:nth-last-child(3),
.flat.wide .show:first-child:nth-last-child(3) ~ .show.tiny {
  padding-bottom: 25.78244579%;
}
@media (min-width: 1400px) {
  .flat.wide .show.tiny:first-child:nth-last-child(3),
  .flat.wide .show:first-child:nth-last-child(3) ~ .show.tiny {
    padding-bottom: 25.84962963%;
  }
}
.area.area {
  width: 103.15789474%;
  margin-left: -1.57894737%;
}
@media (min-width: 1400px) {
  .area.area {
    width: 103.05343511%;
    margin-left: -1.52671756%;
  }
}
.unit {
  margin-top: 1.65816327%;
  margin-bottom: 1.65816327%;
}
@media (min-width: 1400px) {
  .unit {
    margin-top: 1.55555556%;
    margin-bottom: 1.55555556%;
  }
}
@media (min-width: 1400px) {
  #view .seam .show + .text.text {
    font-size: 1.4vw;
  }
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
/*# sourceMappingURL=./screen-large.css.map */